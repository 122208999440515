// utils/API.js

import axios from "axios";
import PARAMS from "utils/PARAMS";
import { toast } from "react-toastify";
import { red } from "@material-ui/core/colors";
import { useSelector } from "react-redux";
import { RssFeed } from "@material-ui/icons";

// Instancia para api de partes
var instance = axios.create({
  baseURL: PARAMS.apiUrl,
  timeout: 10000000,
});
let isLogged = localStorage.getItem("apiToken") ? true : false;

instance.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("apiToken");
instance.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          if (localStorage.getItem("apiToken")) {
            toast("Sesión caducada", { type: "error" });
            axios.get("logout");
            localStorage.removeItem("apiToken");
            localStorage.removeItem("userType");
            localStorage.removeItem("userEmail");
            window.location.replace(PARAMS.baseUrl);
          } else {
            // toast("Usuario no autorizado", { type: "error" });
          }
          break;
        case 404:
          toast("Url no disponible", { type: "error" });
          break;
        case 500:
          break;

        default:
      }
    } else {
      toast("No se ha podido establecer conexión", { type: "error" });
    }

    return Promise.reject(error);
  }
);

// Instancia para ws formData
var instance_fd = axios.create({
  baseURL: PARAMS.apiUrl,
  timeout: 5000,
});

instance_fd.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("apiToken");
instance_fd.defaults.headers.common["Content-Type"] = "multipart/form-data";
instance_fd.interceptors.response.use(
  async function (response) {
    return response;
  },

  async function (error) {
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          if (localStorage.getItem("apiToken")) {
            toast("Sesión caducada", { type: "error" });
            axios.get("logout");
            localStorage.removeItem("apiToken");
            localStorage.removeItem("userEmail");
            localStorage.removeItem("userType");
            window.location.replace(PARAMS.baseUrl);
          } else {
            // toast("Usuario no autorizado", { type: "error" });
          }
          break;
        case 404:
          toast("Url no disponible", { type: "error" });
          break;
        case 500:
          break;
        default:
      }
    }
  }
);

// Funcion auxiliar async
async function resolve(promise) {
  const resolved = {
    data: null,
    error: null,
  };

  try {
    resolved.data = await promise;
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error) {
      resolved.error = e.response.data.error;
    } else {
      resolved.error = "Se ha producido un error";
    }
  }

  return resolved;
}

// File a base64
const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

// Ruta para verificar tipo de usuario (token)
export async function isAdminAuth() {
  return await resolve(instance.post("isAdminAuth").then((res) => res.data));
}

export async function getPaymentsEstablecimiento(id) {
  return await resolve(
    instance.get(`metodos-de-pago/getPayments/${id}`).then((res) => res.data)
  );
}

export async function actualizarEstadoPedido(
  estadoPedido,
  idsPedido,
  errorMessage,
  pagoId
) {
  return await resolve(
    instance
      .post("pedidos/actualizar-estado-pedido", {
        estadoPedido,
        idsPedido,
        errorMessage,
        pagoId,
      })
      .then((res) => res.data)
  );
}

export async function crearPedido(establecimientos) {
  return await resolve(
    instance.post("pedidos/store", { establecimientos }).then((res) => res.data)
  );
}

// Ruta para verificar tipo de usuario (no token)
export async function isAdminGuest() {
  return await resolve(instance.post("isAdminGuest").then((res) => res.data));
}

export async function tarjetaPorDefecto() {
  return await resolve(
    instance.post("stripe/tarjeta-por-defecto").then((res) => res.data)
  );
}

export async function login(email, password) {
  return await resolve(
    instance.post("login", { email, password }).then((res) => res.data)
  );
}

export async function actualizarTokenSuperAdmin(token) {
  return await resolve(
    instance.post("tokenSuperAdmin", { token }).then((res) => res.data)
  );
}

export async function obtenerTokenSuperAdmin(token) {
  return await resolve(
    instance.post("showTokenSuperAdmin", { token }).then((res) => res.data)
  );
}

export async function loginSuperAdmin(email, password) {
  let tipoUsuario = "adminEstablecimiento";
  return await resolve(
    instance
      .post("loginSuperAdmin/entrar-dashboard", {
        email,
        password,
        tipoUsuario,
      })
      .then((res) => res.data)
  );
}

export async function register(nuevoUsuario) {
  return await resolve(
    instance.post("register", { nuevoUsuario }).then((res) => res.data)
  );
}

export async function paymentMethods() {}

export async function nuevoUsuario(
  nombre,
  apellidos,
  nif,
  email,
  password,
  userType
) {
  return await resolve(
    instance
      .post("register", { nombre, apellidos, nif, email, password, userType })
      .then((res) => res.data)
  );
}

export async function resetPass(email) {
  return await resolve(
    instance.post("resetPassword/create", { email }).then((res) => res.data)
  );
}

export async function logout() {
  return await resolve(instance.get("logout").then((res) => res.data));
}

export async function getPermiso() {
  return await resolve(instance.post("permiso/index").then((res) => res.data));
}

/** --------------------------------------------------------------------------------
 * ------------------------------ DashBoard Admin ----------------------------------
 * ---------------------------------------------------------------------------------*/

export async function dashboardAdminTotalDinero() {
  return await resolve(
    instance.post("dashboardAdminTotalDinero").then((res) => res.data)
  );
}

export async function dashboardAdminTotalPedidos() {
  return await resolve(
    instance.post("dashboardAdminTotalPedidos").then((res) => res.data)
  );
}

export async function dashboardAdminTotalTiendas() {
  return await resolve(
    instance.post("dashboardAdminTotalTiendas").then((res) => res.data)
  );
}

export async function dashboardAdminTotalUsuarios() {
  return await resolve(
    instance.post("dashboardAdminTotalUsuarios").then((res) => res.data)
  );
}

export async function dashboardAdminTotalProductos() {
  return await resolve(
    instance.post("dashboardAdminTotalProductos").then((res) => res.data)
  );
}

/** ---------------------------------------------------------------------------------
 * ------------------------------ DashBoard Tienda ----------------------------------
 * ---------------------------------------------------------------------------------*/

export async function dashboardTiendaGetIdEstablecimiento(idUser) {
  return await resolve(
    instance
      .post("dashboardTiendaGetIdEstablecimiento", { idUser })
      .then((res) => res.data)
  );
}

export async function dashboardTiendaTotalClientes(idEstablecimiento) {
  return await resolve(
    instance
      .post("dashboardTiendaTotalClientes", { idEstablecimiento })
      .then((res) => res.data)
  );
}

export async function dashboardTiendaTotalPedidos(idEstablecimiento) {
  return await resolve(
    instance
      .post("dashboardTiendaTotalPedidos", { idEstablecimiento })
      .then((res) => res.data)
  );
}

export async function dashboardTiendaTotalDinero(idEstablecimiento) {
  return await resolve(
    instance
      .post("dashboardTiendaTotalDinero", { idEstablecimiento })
      .then((res) => res.data)
  );
}

export async function dashboardTiendaTotalDevoluciones(idEstablecimiento) {
  return await resolve(
    instance
      .post("dashboardTiendaTotalDevoluciones", { idEstablecimiento })
      .then((res) => res.data)
  );
}

export async function dashboardTiendaTotalProductos(idEstablecimiento) {
  return await resolve(
    instance
      .post("dashboardTiendaTotalProductos", { idEstablecimiento })
      .then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Descuento Promocional --------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function getPromociones() {
  return await resolve(
    instance
      .post("descuento-promocional/establecimiento")
      .then((res) => res.data)
  );
}

export async function promocionesPorEstablecimiento(search, page, perPageData) {
  return await resolve(
    instance
      .post("descuento-promocional/por-establecimiento", {
        search,
        page,
        perPageData,
      })
      .then((res) => res.data)
  );
}

export async function updateCodigoPromocional(promocion) {
  return await resolve(
    instance
      .post("descuento-promocional/update", { promocion })
      .then((res) => res.data)
  );
}

export async function deleteCodigoPromocional(id) {
  return await resolve(
    instance.post(`descuento-promocional/destroy/${id}`).then((res) => res.data)
  );
}

export async function codigoPromocionalCategoriaUpdate(
  categoria_id,
  codigoPromocional
) {
  return await resolve(
    instance
      .post(`descuento-promocional/categoria/update`, {
        categoria_id,
        codigoPromocional,
      })
      .then((res) => res.data)
  );
}
export async function codigoPromocionalCategoriaDelete(categoriaId) {
  return await resolve(
    instance
      .post(`descuento-promocional/categoria/delete`, { categoriaId })
      .then((res) => res.data)
  );
}

export async function eliminarCodigos(codigosSeleccionados) {
  return await resolve(
    instance
      .post(`descuento-promocionales/delete`, { codigosSeleccionados })
      .then((res) => res.data)
  );
}

export async function eliminarCodigosCategorias(categoriasSeleccionadas) {
  return await resolve(
    instance
      .post("descuento-promocionales/categorias/eliminar", {
        categoriasSeleccionadas,
      })
      .then((res) => res.data)
  );
}

export async function editarCodigos(codigosSeleccionados, descuento) {
  return await resolve(
    instance
      .post(`descuento-promocionales/edit`, { codigosSeleccionados, descuento })
      .then((res) => res.data)
  );
}

export async function editarCodigosCategorias(
  categoriasSeleccionadas,
  codigoPromocional
) {
  return await resolve(
    instance
      .post("descuento-promocionales/edit/categorias", {
        categoriasSeleccionadas,
        codigoPromocional,
      })
      .then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Zonas Reparto --------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function getZonasReparto() {
  return await resolve(instance.post("zonas/index").then((res) => res.data));
}

export async function crearZonaReparto(newTarifas) {
  return await resolve(
    instance.post("zonas/crear", { newTarifas }).then((res) => res.data)
  );
}

export async function getMisTarifas() {
  return await resolve(
    instance.post("zonas/mis-tarifas").then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Comprobar Cuentas --------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function comprobarCuentaEstablecimiento() {
  return await resolve(
    instance.post("comprobar-cuenta/establecimiento").then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Promociones Producto --------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function promocionStore(promocion, producto) {
  return await resolve(
    instance
      .post("promocion-producto/store", { promocion, producto })
      .then((res) => res.data)
  );
}

export async function promocionUpdate(promocion, producto) {
  return await resolve(
    instance
      .post("promocion-producto/update", { promocion, producto })
      .then((res) => res.data)
  );
}

export async function promocionDelete(producto, id) {
  return await resolve(
    instance
      .post(`promocion-producto/destroy/${id}`, { producto })
      .then((res) => res.data)
  );
}

export async function obtenerPromocionProducto(idProducto) {
  return await resolve(
    instance
      .post("promocion-producto/show", { idProducto })
      .then((res) => res.data)
  );
}

export async function comprobarStockProductoPromocion(
  cantidad,
  productoId,
  tipoPromocion
) {
  return await resolve(
    instance
      .post("promocion-producto/control-stock", {
        cantidad,
        productoId,
        tipoPromocion,
      })
      .then((res) => res.data)
  );
}

export async function quitarPromocion(productosSeleccionados) {
  return await resolve(
    instance
      .post("promociones-productos/delete", { productosSeleccionados })
      .then((res) => res.data)
  );
}

export async function editarPromociones(productosSeleccionados, promocion) {
  return await resolve(
    instance
      .post("promociones-productos/edit", { productosSeleccionados, promocion })
      .then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Etiquetas --------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function getEtiquetas(search, page, perPageData) {
  return await resolve(
    instance
      .post("etiquetas/index", { search, page, perPageData })
      .then((res) => res.data)
  );
}

export async function crearEtiqueta(etiqueta, atributo) {
  return await resolve(
    instance
      .post("etiquetas/store", { etiqueta, atributo })
      .then((res) => res.data)
  );
}

export async function crearEtiquetaMultiple(etiqueta, atributos) {
  return await resolve(
    instance
      .post("etiquetas/multiple/store", { etiqueta, atributos })
      .then((res) => res.data)
  );
}

export async function actualizarEtiqueta(etiqueta, atributos) {
  return await resolve(
    instance
      .post("etiquetas/update", { etiqueta, atributos })
      .then((res) => res.data)
  );
}

export async function eliminarEtiqueta(id) {
  return await resolve(
    instance.post(`etiquetas/destroy/${id}`).then((res) => res.data)
  );
}

export async function eliminarEtiquetasMultiple(etiquetas) {
  return await resolve(
    instance
      .post("etiquetas/multiple/delete", { etiquetas })
      .then((res) => res.data)
  );
}

export async function obtenerEtiquetasDisponibles(etiquetas, id) {
  return await resolve(
    instance
      .post(`etiquetas/disponibles/${id}`, { etiquetas })
      .then((res) => res.data)
  );
}

export async function etiquetasEstablecimientoDisponibles() {
  return await resolve(
    instance
      .post(`etiquetas/establecimientos/disponibles`)
      .then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Usuarios --------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function getUser(email_usuario) {
  return await resolve(
    instance.post("usuarios/show", { email_usuario }).then((res) => res.data)
  );
}

export async function getUserPerfil(email_usuario) {
  return await resolve(
    instance
      .post("usuarios/showPerfil", { email_usuario })
      .then((res) => res.data)
  );
}

export async function obtenerInfoCliente() {
  return await resolve(instance.post("clientes/show").then((res) => res.data));
}

export async function infoClientes(idCliente) {
  return await resolve(
    instance
      .post("clientes/infoCliente/show", { idCliente })
      .then((res) => res.data)
  );
}

export async function userIndex(search, page, rolUsuario, perPageData) {
  return await resolve(
    instance
      .post("usuarios/index", { search, page, rolUsuario, perPageData })
      .then((res) => res.data)
  );
}

export async function usuarioStore(usuario) {
  return await resolve(
    instance.post("usuarios/store", usuario).then((res) => res.data)
  );
}

export async function changePassword(listPassword) {
  return await resolve(
    instance
      .post("usuarios/changePassword", { listPassword })
      .then((res) => res.data)
  );
}

export async function updatePerfil(user) {
  return await resolve(
    instance.post("usuarios/updatePerfil", { user }).then((res) => res.data)
  );
}

export async function eliminarUsuarios(usuariosSeleccionados) {
  return await resolve(
    instance
      .post("usuarios/delete", { usuariosSeleccionados })
      .then((res) => res.data)
  );
}

export async function controlStock(cantidad, cantidadLocalStorage, idProducto) {
  return await resolve(
    instance
      .get(
        `productos/control-stock/${cantidad}/${cantidadLocalStorage}/${idProducto}`
      )
      .then((res) => res.data)
  );
}

export async function infoProductos(datos, userEmail) {
  return await resolve(
    instance
      .post("info-productos", { datos, userEmail })
      .then((res) => res.data)
  );
}

export async function controlStockCambioCantidad(
  cantidadLocalStorage,
  idProducto
) {
  return await resolve(
    instance
      .get(
        `productos/control-stock/cambio-cantidad/${cantidadLocalStorage}/${idProducto}`
      )
      .then((res) => res.data)
  );
}

export async function getDetalleCarrito(establecimiento) {
  return await resolve(
    instance
      .post("carrito/detalle", { establecimiento })
      .then((res) => res.data)
  );
}

export async function cargarCarrito(email, productos, numArticulos) {
  return await resolve(
    instance
      .post(`carrito/mi-carrito`, { email, productos, numArticulos })
      .then((res) => res.data)
  );
}

export async function calcularCantidadPromociones(carrito) {
  return await resolve(
    instance.post("carrito/calcularStockProductoPromocion", { carrito })
  );
}

export async function comprobarCarrito(carrito) {
  return await resolve(instance.post("carrito/comprobarCarrito", { carrito }));
}

export async function actualizarCarritoLogin(carrito, numArticulos) {
  let auxNumArticulos = parseInt(localStorage.getItem("numArticulos"));

  return await resolve(
    instance
      .post("carrito/actualizar-carrito", { carrito, numArticulos })
      .then((res) => res.data)
  );
}

export async function borrarLineaCarrito(idProducto, idEstablecimiento) {
  return await resolve(
    instance
      .post("carrito/eliminar-linea-carrito", {
        idProducto,
        idEstablecimiento,
      })
      .then((res) => res.data)
  );
}

export async function getCarritoUser() {
  return await resolve(
    instance.post("carrito/detalleCarrito").then((res) => res.data)
  );
}

export async function comprobarCupon(cupon, productoId) {
  return await resolve(
    instance
      .post("carrito/comprobar-cupon", { cupon, productoId })
      .then((res) => res.data)
  );
}

export async function userStore(user) {
  return await resolve(
    instance.post("usuarios/store", user).then((res) => res.data)
  );
}

export async function userUpdate(usuario, pass) {
  let fd = new FormData();

  for (var key in usuario) {
    if (usuario[key]) {
      fd.append(key, usuario[key]);
    }
  }

  if (pass) {
    fd.append("password", pass);
  }

  return await resolve(
    instance_fd
      .post(`usuarios/update/${usuario.id}`, fd)
      .then((res) => res.data)
  );
}

export async function putUser(usuario) {
  return await resolve(
    instance_fd.post("usuarios/put", usuario).then((res) => res.data)
  );
}

// export async function userUpdate(usuario, pass) {
//   let fd = new FormData();

//   for (var key in usuario) {
//     if (usuario[key]) {
//       fd.append(key, usuario[key]);
//     }
//   }

//   if (pass) {
//     fd.append("password", pass);
//   }

//   return await resolve(
//     instance_fd
//       .post(`usuarios/update/${usuario.id}`, fd)
//       .then((res) => res.data)
//   );
// }

export async function userDestroy(id) {
  return await resolve(
    instance.post(`usuarios/destroy/${id}`).then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * --------------------- Direcciones Facturaciones y Entregas -----------------------------
 * ----------------------------------------------------------------------------------------*/

export async function getFacturaciones() {
  return await resolve(instance.post("facturaciones").then((res) => res.data));
}

export async function saveFacturacion(facturacion) {
  return await resolve(
    instance.post("facturaciones/store", facturacion).then((res) => res.data)
  );
}

export async function editFacturacion(facturacion) {
  return await resolve(
    instance
      .post(`facturaciones/update/${facturacion.id}`, facturacion)
      .then((res) => res.data)
  );
}

export async function deleteFacturacion(idFacturacion) {
  return await resolve(
    instance
      .post(`facturaciones/destroy/${idFacturacion}`)
      .then((res) => res.data)
  );
}

export async function changeFacturacionPredeterminada(idFacturacion) {
  return await resolve(
    instance
      .post(`facturaciones/predeterminada`, { idFacturacion })
      .then((res) => res.data)
  );
}

export async function getEntregas() {
  return await resolve(instance.post("entregas").then((res) => res.data));
}

export async function saveEntrega(entrega) {
  return await resolve(
    instance.post("entregas/store", entrega).then((res) => res.data)
  );
}

export async function editEntrega(entrega) {
  return await resolve(
    instance
      .post(`entregas/update/${entrega.id}`, entrega)
      .then((res) => res.data)
  );
}

export async function deleteEntrega(idEntrega) {
  return await resolve(
    instance.post(`entregas/destroy/${idEntrega}`).then((res) => res.data)
  );
}

export async function changeEntregaPredeterminada(idEntrega) {
  return await resolve(
    instance
      .post(`entregas/predeterminada`, { idEntrega })
      .then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------- Poblaciones y Codigos Postales ---------------------------------
 * ----------------------------------------------------------------------------------------*/

// export async function getProvincias() {
//   return await resolve(instance.post("provincias").then((res) => res.data));
// }

export async function getProvincias() {
  return await resolve(instance.get("provincias").then((res) => res.data));
}

export async function getProvinciasOfComunidad(idComunidad) {
  return await resolve(
    instance.post(`provincias/${idComunidad}`).then((res) => res.data)
  );
}

export async function getComunidades() {
  return await resolve(instance.post("comunidades").then((res) => res.data));
}

export async function getPoblaciones(idProvincia) {
  return await resolve(
    instance.post(`poblaciones/${idProvincia}`).then((res) => res.data)
  );
}

export async function getCodigosPostales(idProvincia, idPoblacion) {
  return await resolve(
    instance
      .post(`codigosPostales/${idProvincia}/${idPoblacion}`)
      .then((res) => res.data)
  );
}

export async function getCodigosPostalesEntregas(idProvincia, idPoblacion) {
  return await resolve(
    instance
      .post(`codigosPostalesEntregas/${idProvincia}/${idPoblacion}`)
      .then((res) => res.data)
  );
}

export async function changeStateCodigoPostal(stateCodPostal) {
  return await resolve(
    instance
      .post(`codigosPostales/change_state`, stateCodPostal)
      .then((res) => res.data)
  );
}

export async function getCodigosPostalesDisabled() {
  return await resolve(
    instance.post(`codigosPostales/deactivated`).then((res) => res.data)
  );
}

export async function isCodPostalEnabled(
  codigoPostal,
  idPoblacion,
  idProvincia
) {
  return await resolve(
    instance
      .post(`codigoPostalEnabled`, { codigoPostal, idPoblacion, idProvincia })
      .then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- CLientes -------------------------------------------
 * ----------------------------------------------------------------------------------------*/
export async function cliente(id) {
  return await resolve(
    instance.post(`clientes/show/${id}`).then((res) => res.data)
  );
}

export async function clienteStore(cliente) {
  return await resolve(
    instance.post("clientes/store", cliente).then((res) => res.data)
  );
}

export async function eliminarCliente(clienteId) {
  return await resolve(
    instance.post(`clientes/destroy/${clienteId}`).then((res) => res.data)
  );
}

export async function eliminarClientesMultiple(clientesSeleccionados) {
  return await resolve(
    instance
      .post("clientes/destroyMultiple", { clientesSeleccionados })
      .then((res) => res.data)
  );
}

export async function nuevaDireccionCliente(
  nombre,
  apellidos,
  empresa,
  iva,
  direccion,
  otraDireccion,
  codigoPostal,
  ciudad,
  pais,
  telefono,
  checkEnvio
) {
  return await resolve(
    instance
      .post("clientes/nueva-direccion", {
        nombre,
        apellidos,
        empresa,
        iva,
        direccion,
        otraDireccion,
        codigoPostal,
        ciudad,
        pais,
        telefono,
        checkEnvio,
      })
      .then((res) => res.data)
  );
}

export async function cambiarDireccionEnvio(id) {
  return await resolve(
    instance
      .post("clientes/cambiar-direccion-envio", { id })
      .then((res) => res.data)
  );
}

export async function cambiarDireccionFacturacion(id) {
  return await resolve(
    instance
      .post("clientes/cambiar-direccion-facturacion", { id })
      .then((res) => res.data)
  );
}

export async function nuevaDireccionFacturacion(
  direccionEnvio,
  direccionFacturacion
) {
  return await resolve(
    instance
      .post("clientes/nueva-direccion-facturacion", {
        direccionEnvio,
        direccionFacturacion,
      })
      .then((res) => res.data)
  );
}

export async function eliminarDireccionEnvio(direccion) {
  return await resolve(
    instance
      .post("clientes/eliminar-direccion-envio", { direccion })
      .then((res) => res.data)
  );
}

export async function eliminarDireccionFacturacion(direccion) {
  return await resolve(
    instance
      .post("clientes/eliminar-direccion-facturacion", { direccion })
      .then((res) => res.data)
  );
}

export async function clientesEstablecimiento(search, page, perPageData) {
  return await resolve(
    instance
      .post("clientes/establecimiento", { search, page, perPageData })
      .then((res) => res.data)
  );
}

export async function obtenerDireccionesCliente(idCliente) {
  return await resolve(
    instance.post("cliente/direcciones", { idCliente }).then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Logs --------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function logIndex() {
  return await resolve(instance.post("log/index").then((res) => res.data));
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Permisos --------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function permisosList(search) {
  return await resolve(
    instance.post("permiso/list", { search }).then((res) => res.data)
  );
}

export async function permisoStore(nombre, permiso_modelos) {
  return await resolve(
    instance
      .post("permiso/store", { nombre, permiso_modelos })
      .then((res) => res.data)
  );
}

export async function permisoUpdate(id, nombre, permiso_modelos) {
  return await resolve(
    instance
      .post(`permiso/update/${id}`, { nombre, permiso_modelos })
      .then((res) => res.data)
  );
}

export async function permisoDestroy(id) {
  return await resolve(
    instance.post(`permiso/destroy/${id}`).then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Establecimientos -----------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function establecimientoWithPayment() {
  return await resolve(
    instance.post("establecimientos/mi-establecimiento").then((res) => res.data)
  );
}

export async function eliminarEstablecimientos(establecimientosSeleccionados) {
  return await resolve(
    instance
      .post("establecimientos/delete", { establecimientosSeleccionados })
      .then((res) => res.data)
  );
}

export async function cambiarLogoEstablecimiento(imagen, id) {
  let data = new FormData();
  data.append("file", imagen);
  data.append("id", id);
  let settings = { headers: { "content-type": "multipart/form-data" } };
  return await resolve(
    instance
      .post("establecimientos/cambiar-logo", data, settings)
      .then((res) => res.data)
  );
}

export async function establecimientoUpdate(
  establecimiento,
  pagosSeleccionados
) {
  let fd = new FormData();
  for (var key in establecimiento) {
    if (establecimiento[key]) {
      fd.append(key, establecimiento[key]);
    }
  }

  if (pagosSeleccionados) {
    fd.append("pagosSeleccionados", pagosSeleccionados);
  }

  return await resolve(
    instance.post("establecimientos/update", fd).then((res) => res.data)
  );
}

export async function misPedidos() {
  return await resolve(
    instance.post("pedidos/mis-pedidos").then((res) => res.data)
  );
}

export async function eliminarMiPedido(id) {
  return await resolve(
    instance.post("pedidos/eliminar-mi-pedido", { id }).then((res) => res.data)
  );
}

export async function eliminarPedidos(pedidosSeleccionados) {
  return await resolve(
    instance
      .post("pedidos/delete", { pedidosSeleccionados })
      .then((res) => res.data)
  );
}

export async function establecimientosIndex(search, page, perPageData) {
  return await resolve(
    instance
      .post("establecimientos/index", { search, page, perPageData })
      .then((res) => res.data)
  );
}

export async function getEstablecimientos(search, page, perPageData) {
  return await resolve(
    instance
      .get("establecimientos", { search, page, perPageData })
      .then((res) => res.data)
  );
}

export async function comprobarProvincia(
  idProvincia,
  idEstablecimiento,
  idProducto
) {
  return await resolve(
    instance
      .post(`provincias/comprobar/${idProvincia}/${idEstablecimiento}`, {
        idProducto,
      })
      .then((res) => res.data)
  );
}

export async function getEstablecimientosWithCategory(idCategory) {
  return await resolve(
    instance
      .get(`establecimientoWithCategory/${idCategory}`)
      .then((res) => res.data)
  );
}

export async function getEstablecimiento(id) {
  return await resolve(
    instance.get(`establecimiento/${id}`).then((res) => res.data)
  );
}

export async function establecimientosStore(establecimiento) {
  let fd = new FormData();
  let establecimientoCopy = JSON.parse(JSON.stringify(establecimiento));
  if (establecimiento.logo) {
    establecimientoCopy.logo = await toBase64(establecimiento.logo);
  }

  for (var key in establecimientoCopy) {
    if (establecimientoCopy[key] || establecimientoCopy[key] === 0) {
      fd.append(key, establecimientoCopy[key]);
    }
  }
  return await resolve(
    instance_fd.post("establecimientos/store", fd).then((res) => res.data)
  );
}

export async function establecimientosUpdate(establecimiento, visibilidad) {
  let fd = new FormData();
  for (var key in establecimiento) {
    if (establecimiento[key]) {
      fd.append(key, establecimiento[key]);
    }
  }

  if (visibilidad) {
    fd.append("visibilidad", visibilidad);
  }

  return await resolve(
    instance_fd
      .post(`establecimientos/update/${establecimiento.id}`, fd)
      .then((res) => res.data)
  );
}

export async function establecimientosDestroy(id) {
  return await resolve(
    instance.post(`establecimientos/destroy/${id}`).then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Categorias -----------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function getCategoriasActivas() {
  return await resolve(
    instance.get("categorias/categoriasActivas").then((res) => res.data)
  );
}

export async function getCategoriasActivasFull() {
  return await resolve(
    instance.get("categorias/categoriasActivasFull").then((res) => res.data)
  );
}

export async function getProductosPorCategoria(id) {
  return await resolve(
    instance.get(`categorias/${id}/productos`).then((res) => res.data)
  );
}

export async function nextProductosPorCategoria({ id, numPage }) {
  return await resolve(
    instance
      .get(`categorias/${id}/productos?page=${numPage}`)
      .then((res) => res.data)
  );
}

export async function getCategoriasEstablecimiento(search, page, perPageData) {
  return await resolve(
    instance
      .post("categorias/mis-categorias", { search, page, perPageData })
      .then((res) => res.data)
  );
}

export async function eliminarCategorias(categoriasSeleccionadas) {
  return await resolve(
    instance
      .post("categorias/delete", { categoriasSeleccionadas })
      .then((res) => res.data)
  );
}

export async function categoriasIndex(search, page, perPageData) {
  return await resolve(
    instance
      .post("categorias/index", { search, page, perPageData })
      .then((res) => res.data)
  );
}
export async function categoriasStore(categoria) {
  let fd = new FormData();
  for (var key in categoria) {
    if (categoria[key]) {
      fd.append(key, categoria[key]);
    }
  }
  return await resolve(
    instance_fd.post("categorias/store", fd).then((res) => res.data)
  );
}
export async function categoriasUpdate(categoria) {
  let fd = new FormData();
  for (var key in categoria) {
    if (categoria[key]) {
      fd.append(key, categoria[key]);
    }
  }
  return await resolve(
    instance_fd
      .post(`categorias/update/${categoria.id}`, fd)
      .then((res) => res.data)
  );
}
export async function categoriasDestroy(id) {
  return await resolve(
    instance.post(`categorias/destroy/${id}`).then((res) => res.data)
  );
}
export async function mostrarTodasCategorias() {
  return await resolve(
    instance.post(`categorias/showAll`).then((res) => res.data)
  );
}

export async function mostrarCategoriasActivas() {
  return await resolve(
    instance.post(`categorias/activas`).then((res) => res.data)
  );
}

export async function comprobarCuentaStripe() {
  return await resolve(
    instance.post("stripe/comprobar-cuenta").then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Stripe ------------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function comprobarStripe(cuenta) {
  return await resolve(
    instance.post("stripe/configuracion", { cuenta }).then((res) => res.data)
  );
}

export async function getEstadoCuenta() {
  return await resolve(
    instance.post("stripe/estado-cuenta").then((res) => res.data)
  );
}

export async function crearIntentoDePago(establecimientos, paymentMethod) {
  return await resolve(
    instance
      .post("stripe/crear-intento-pago", { establecimientos, paymentMethod })
      .then((res) => res.data)
  );
}

export async function realizarTransferencias(
  establecimientos,
  idPaymentMethod,
  arrayStockProductos
) {
  return await resolve(
    instance
      .post("stripe/realizar-transferencia", {
        establecimientos,
        idPaymentMethod,
        arrayStockProductos,
      })
      .then((res) => res.data)
  );
}

export async function misMetodosDePago(establecimientoId) {
  return await resolve(
    instance
      .post("stripe/obtener-metodos-de-pago", { establecimientoId })
      .then((res) => res.data)
  );
}

export async function metodoDePagoPorDefecto(
  establecimientoId,
  payment_method
) {
  return await resolve(
    instance
      .post("stripe/cambiar-pago", { establecimientoId, payment_method })
      .then((res) => res.data)
  );
}

export async function obtenerInfoClienteStripe(establecimientoId) {
  return await resolve(
    instance
      .post("stripe/info-cliente", { establecimientoId })
      .then((res) => res.data)
  );
}

export async function crearCupon(promocion) {
  return await resolve(
    instance.post("stripe/crear-cupon", { promocion }).then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Redsys ------------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function pagarRedsys() {
  return await resolve(instance.get("redsys").then((res) => res.data));
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Productos ------------------------------------------
 * ----------------------------------------------------------------------------------------*/
export async function productoPorId(id) {
  return await resolve(
    instance.get(`productos/show/${id}`).then((res) => res.data)
  );

  // return await resolve(
  //   instance.post(`productos/show/${id}`).then((res) => res.data)
  // );
}

export async function addCart(idProducto) {
  return await resolve(
    instance.get(`/controlStock/${idProducto}`).then((res) => res.data)
  );
}

export async function productosPorCategoria(id) {
  return await resolve(
    instance.get(`productos/porCategoria/${id}`).then((res) => res.data)
  );
  // return await resolve(
  //   instance.post(`productos/porCategoria/${id}`).then((res) => res.data)
  // );
}

export async function productosPorEstablecimiento(id) {
  return await resolve(
    instance.get(`productos-establecimiento/${id}`).then((res) => res.data)
  );
}

export async function eliminarProductos(productosSeleccionados) {
  return await resolve(
    instance
      .post("productos/delete", { productosSeleccionados })
      .then((res) => res.data)
  );
}

export async function productosIndex(search, page, perPageData) {
  return await resolve(
    instance
      .post("productos/index", { search, page, perPageData })
      .then((res) => res.data)
  );
}

export async function listadoProductos(props, page) {
  return await resolve(
    instance
      .post("listadoProductos?page=" + page, { props })
      .then((res) => res.data)
  );
}

export async function productosCatalogoIndex(
  search,
  page,
  estadoProducto,
  perPageData
) {
  return await resolve(
    instance
      .post("productos/establecimiento/index", {
        search,
        page,
        estadoProducto,
        perPageData,
      })
      .then((res) => res.data)
  );
}

export async function productosCatalogoIndexActivos(search, page, perPageData) {
  return await resolve(
    instance
      .post("productos-activos/establecimiento/index", {
        search,
        page,
        perPageData,
      })
      .then((res) => res.data)
  );
}

export async function getLastProducts(
  withPromotion,
  unids,
  sameCategory,
  product_id
) {
  return await resolve(
    instance
      .post("productos/lastProducts", {
        withPromotion,
        unids,
        sameCategory,
        product_id,
      })
      .then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Decuentos ------------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function modificarDescuento(producto) {
  return await resolve(
    instance.post("descuentos/editar", { producto }).then((res) => res.data)
  );
}

export async function eliminarDescuentoProducto(id, tipo) {
  return await resolve(
    instance.post("descuentos/eliminar", { id, tipo }).then((res) => res.data)
  );
}

export async function eliminarDescuento(producto, id) {
  return await resolve(
    instance
      .post("productos/descuento/eliminar", { producto, id })
      .then((res) => res.data)
  );
}

export async function eliminarDescuentos(productosSeleccionados, id) {
  return await resolve(
    instance
      .post("productos/descuentos/eliminar", { productosSeleccionados, id })
      .then((res) => res.data)
  );
}

export async function editarDescuentosProductos(
  productosSeleccionados,
  descuento,
  codigoPromocional
) {
  return await resolve(
    instance
      .post("productos/editar/descuentos", {
        productosSeleccionados,
        descuento,
        codigoPromocional,
      })
      .then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Pedidos ------------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function pedidosIndex(search, page, estadoPedido, perPageData) {
  return await resolve(
    instance
      .post("pedidos/establecimiento/index", {
        search,
        page,
        estadoPedido,
        perPageData,
      })
      .then((res) => res.data)
  );
}

export async function sendLocalizador(objLocalizador) {
  return await resolve(
    instance
      .post("pedidos/enviar-localizador", { objLocalizador })
      .then((res) => res.data)
  );
}

export async function cancelarPedido(pedido, motivo) {
  return await resolve(
    instance
      .post("pedidos/cancelar-pedido", { pedido, motivo })
      .then((res) => res.data)
  );
}

export async function actualizarPedido(status, pedidoId) {
  return await resolve(
    instance
      .post(`pedidos/actualizar-pedido/${pedidoId}`, { status, pedidoId })
      .then((res) => res.data)
  );
}

export async function eliminarPedido(id) {
  return await resolve(
    instance.post("pedidos/eliminar-pedido", { id }).then((res) => res.data)
  );
}

// export async function cambiarLogoEstablecimiento(imagen, id) {
//   let data = new FormData();
//   data.append('file', imagen);
//   data.append('id', id);
//   let settings = { headers: { 'content-type': 'multipart/form-data' } }
//   return await resolve(
//     instance
//       .post("establecimientos/cambiar-logo", data, settings)
//       .then((res) => res.data)
//   )
// }

export async function productosStore(producto) {
  if (producto.imagenes) {
    for (var key in producto.imagenes) {
      producto.imagenes[key] = await toBase64(producto.imagenes[key]);
    }
  }
  let fd = new FormData();

  for (var key in producto) {
    if (producto[key]) {
      if (Array.isArray(producto[key])) {
        fd.append(key, JSON.stringify(producto[key]));
      } else {
        fd.append(key, producto[key]);
      }
    }
  }

  return await resolve(
    instance_fd.post("productos/store", fd).then((res) => res.data)
  );
}

export async function productosUpdate(producto, tipo) {
  if (producto.imagenes) {
    for (var key in producto.imagenes) {
      if (producto.imagenes[key].name) {
        producto.imagenes[key] = await toBase64(producto.imagenes[key]);
      }
    }
  }

  let fd = new FormData();
  for (var key in producto) {
    if (producto[key]) {
      if (Array.isArray(producto[key])) {
        fd.append(key, JSON.stringify(producto[key]));
      } else {
        fd.append(key, producto[key]);
      }
    }
  }

  return await resolve(
    instance_fd
      .post(`productos/update/${producto.id}`, fd)
      .then((res) => res.data)
  );
}

export async function mostrarEstablecimientos() {
  return await resolve(
    instance.post("establecimientos/index").then((res) => res.data)
  );
}

export async function eliminarArchivo(imagen) {
  return await resolve(
    instance.post("archivos/eliminar", { imagen }).then((res) => res.data)
  );
}

export async function verProducto(id) {
  return await resolve(
    instance.post(`productos/show/${id}`).then((res) => res.data)
  );
}

export async function productosDestroy(id) {
  return await resolve(
    instance.post(`productos/destroy/${id}`).then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Devoluciones ---------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function crearDevolucion(devolucion) {
  return await resolve(
    instance.post(`devoluciones/store`, devolucion).then((res) => res.data)
  );
}

export async function getDevolucion(detalle_pedido_id) {
  return await resolve(
    instance
      .post(`devoluciones/show/${detalle_pedido_id}`)
      .then((res) => res.data)
  );
}

export async function getDevolucionesPorEstablecimiento(user_id) {
  return await resolve(
    instance
      .post(`devoluciones/establecimiento/${user_id}`)
      .then((res) => res.data)
  );
}

export async function changeStateDevolucion(id, actualState, observation) {
  return await resolve(
    instance
      .post(`devoluciones/changeState`, { id, actualState, observation })
      .then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- ClienteDatosFactura ---------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function clienteDatosFactura(id) {
  return await resolve(
    instance.post(`clienteDatosFactura/show/${id}`).then((res) => res.data)
  );
}

export async function clienteDatosFacturaStore(clienteDatosFactura) {
  return await resolve(
    instance
      .post("clienteDatosFactura/store", clienteDatosFactura)
      .then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Pagos -----------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function obtenerMetodosDePago(clienteDatosFactura) {
  return await resolve(
    instance.post("metodos-de-pago/index").then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- FAQ --------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function getFaqs(search, page, perPageData) {
  return await resolve(
    instance
      .post("faq/index", { search, page, perPageData })
      .then((res) => res.data)
  );
}

export async function createFaq(faq) {
  return await resolve(
    instance.post("faq/store", { faq }).then((res) => res.data)
  );
}

export async function deleteFaq(id) {
  return await resolve(
    instance.post(`faq/eliminar/${id}`).then((res) => res.data)
  );
}

export async function updateFaq(faq) {
  return await resolve(
    instance.post("faq/actualizar", { faq }).then((res) => res.data)
  );
}

export async function eliminarFaqs(faqsSeleccionadas) {
  return await resolve(
    instance
      .post("faq/multi-eliminar", { faqsSeleccionadas })
      .then((res) => res.data)
  );
}

export async function getTodasFaqs() {
  return await resolve(instance.get("faq/indice").then((res) => res.data));
}

/** --------------------------------------------------------------------------------------
 * ------------------------------- WHATSAPP SUBSCRIPTION ----------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function setNewUserWhatsapp({ establecimiento_id, telefono }) {
  return await resolve(
    instance
      .post("whatsapp", { establecimiento_id, telefono })
      .then((res) => res.data)
  );
}

export async function createSuscriptor(user_id, telefono) {
  return await resolve(
    instance
      .post("suscripciones/create", { user_id, telefono })
      .then((res) => res.data)
  );
}

export async function getSubscripcionesPorEstablecimiento(
  id,
  page,
  search,
  perPageData
) {
  return await resolve(
    instance
      .post(`subscripciones/${id}`, { search, page, perPageData })
      .then((res) => res.data)
  );
}

export async function updateSuscripcion(suscripcion) {
  return await resolve(
    instance
      .post("suscripcion/actualizar", { suscripcion })
      .then((res) => res.data)
  );
}

export async function eliminarSuscripciones(suscripcionesSeleccionadas) {
  return await resolve(
    instance
      .post("suscripcion/multi-eliminar", { suscripcionesSeleccionadas })
      .then((res) => res.data)
  );
}

export async function deleteSuscripcion(id) {
  return await resolve(
    instance.post(`suscripcion/eliminar/${id}`).then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ------------------------------- GASTOS ENVIO X PRODUCTO ----------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function gastosEnvioPorProducto(idProducto) {
  return await resolve(
    instance
      .post(`gastosEnvioPorProducto/${idProducto}`)
      .then((res) => res.data)
  );
}
