import React from "react";
import logoAyuntamiento from "assets/img/logo-delegacion-cordoba.png";
import { makeStyles } from "@material-ui/core/styles";
import logoComercioCordoba from "assets/img/comercio_cordoba.jpg";
import logoCentroCordoba from "assets/img/centro_cordoba.png";
import logoComercialVinuela from "assets/img/centro_comercial_vinuela.png";
import logoComercialCiudadJardin from "assets/img/centro_comercial_ciudad_jardin.jpg";
import logoComercioSantaRosa from "assets/img/comercio_santa_rosa.png";
import { Autocomplete } from "@material-ui/lab";

const listColaboradores = [
  { src: logoComercioCordoba, alt: "logo comercio de Córdoba", ancho: false },
  { src: logoCentroCordoba, alt: "logo Centro Córdoba", ancho: false },
  { src: logoComercialVinuela, alt: "Logo Comercial Vinuela", ancho: true },
  {
    src: logoComercialCiudadJardin,
    alt: "Logo Comercial Ciudad Jardin",
    ancho: false,
  },
  {
    src: logoComercioSantaRosa,
    alt: "Logo Comercio Santa Rosa",
    ancho: true,
  },
];

const useStyles = makeStyles({
  ContainerDelegacion: {
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
    margin: "0 auto",
    width: "100%",
  },
  subContainerDelegacion: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "1200px",
    backgroundColor: "#ffffff",
    width: "100%",
    ["@media (max-width: 800px)"]: {
      flexDirection: "column",
    },
  },
  subvenciona: {
    textAlign: "center",
  },
  colabora: {
    textAlign: "center",
  },
  colaboraText: {
    fontSize: "1rem",
    color: "#000000",
    ["@media (max-width: 800px)"]: {
      margin: "2rem 0",
      fontSize: "1.5rem",
    },
  },
  listColabora: {
    display: "flex",
    ["@media (max-width: 800px)"]: {
      flexDirection: "column",
    },
  },
  subencionaText: {
    fontSize: "1rem",
    color: "#000000",
    ["@media (max-width: 800px)"]: {
      margin: "2rem 0",
      fontSize: "1.5rem",
    },
  },
  pictureColaborador: {
    maxHeight: "75px",
    height: "75px",
    display: "grid",
    placeItems: "center",
    margin: "1rem",
    width: "100%",
    ["@media (max-width: 800px)"]: {
      margin: "2rem 0",
      maxHeight: "125px",
      height: "125px",
    },
  },
  imgColaborador: {
    maxWidth: "100%",
    height: "auto",
    maxHeight: "75px",
    objectFit: "cover",
    ["@media (max-width: 800px)"]: {
      maxHeight: "125px",
    },
  },
  ancho: {
    width: "100%",
    ["@media (max-width: 800px)"]: {
      width: "100%",
    },
  },
  subHeader: {
    height: "40px",
    minHeight: "40px",
    backgroundColor: "#289397",
    width: "100%",
  },
});

export default function DelegacionFooter() {
  const {
    ContainerDelegacion,
    subContainerDelegacion,
    subencionaText,
    subvenciona,
    colabora,
    colaboraText,
    listColabora,
    pictureColaborador,
    imgColaborador,
    ancho,
    subHeader,
  } = useStyles();
  return (
    <>
      <div className={subHeader}></div>
      <section className={ContainerDelegacion}>
        <div className={subContainerDelegacion}>
          {/* SUBVENCIONA */}
          <div className={subvenciona}>
            <p className={subencionaText}>Subvenciona</p>
            <picture>
              <img
                src={logoAyuntamiento}
                alt="Delegacion de Comercio Logo"
              ></img>
            </picture>
          </div>
          {/* COLABORA */}
          <div className={colabora}>
            <p className={colaboraText}>Colabora</p>
            <div className={listColabora}>
              {listColaboradores.length > 0 &&
                listColaboradores.map((colaborador) => (
                  <picture
                    className={`${pictureColaborador} ${
                      colaborador.ancho ? ancho : ""
                    }`}
                  >
                    <img
                      className={imgColaborador}
                      src={colaborador.src}
                      alt={colaborador.alt}
                    ></img>
                  </picture>
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
