import loginAdminImg from "assets/img/admin_img.jpg";
import iconImg from "assets/img/logo_header.png";
import sideBarImg from "assets/img/sidebar-3.jpg";

let tokenAleatorio = Math.random().toString(36);

var params = [];
params["baseUrl"] = "https://formacionmarketplace.aicor.es/";
params["apiUrl"] = "https://api.formacionmarketplace.aicor.es/api";
params["urlImagenes"] = "https://api.formacionmarketplace.aicor.es/storage";
params["urlBackend"] = "https://api.formacionmarketplace.aicor.es/test";
params["loginAdminImg"] = loginAdminImg;
params["sideBarIcon"] = iconImg;
params["sideBarImg"] = sideBarImg;
params["sideBarColor"] = "#52bfc4";
params["firstColorRGB"] = "0, 176, 213";
params["firstColor"] = "#52bfc4";
params["firstColorHover"] = "#a8dfe1";
params["secondColor"] = "#bc2f7b";
params["color_green"] = "#3db194";
params["color_green_light"] = "#b9e5e7";
params["color_red"] = "#e43b96";
params["color_purple"] = "#942bac";
params["color_alert"] = "#df073f";
params["color_grey"] = "#556269";
params["color_grey2"] = "#494541";
params["color_grey_light"] = "#f5f5f5";
params["color_yellow"] = "#f9d03f";
params["color_yellow2"] = "#c7a632";
params["color_white"] = "#ffffff";
params["proyectName"] = "MarketPlace";
params["defaultTableLength"] = 10;
params["tableLengthArray"] = [10, 25, 50];
params["personalizacion"] = false;
params["dev_mode"] = true;
params["panel_url"] = "administrador";
params["web_url"] = "entidad";
params["console_log"] = true;
params["STRIPE_PUBLIC_KEY"] =
 // "pk_live_51KWx3rKNbeDjt6UtVUuFbsaxEGPgkpenki2HzIHWU5Qzji9xmkcKm9IKI1vnZsQMrmkpley6g2AuGBKOh9z6Bi5h00TiiBcLfj";
 "pk_test_51JeDexBkAWmv2huSoOtOM9NTi9lwkN7MJQzNzEGDeLyinkAc2UbaND9khfQcV1nkqFSV4DzkraYVucIH9o21yDWu006ebSEqeo";

// Para la zona admin
params["zonaAdmin"] = btoa(tokenAleatorio);
params["STRIPE_ACCOUNT"] = "acct_1JeDexBkAWmv2huS";

export default params;
