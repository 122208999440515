import React from "react";

const Mantenimiento = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <h1>Actualmente estamos en Mantenimiento</h1>;
      <a href="http://dev.cordobadecompras.es/administrador" />;
    </div>
  );
};

export default Mantenimiento;
