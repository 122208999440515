import React from "react";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  ul: {
    fontSize: "13px",
    fontWeight: "700",
    margin: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Lato",
    width: "100%",
    height: "100%",
    color: "white",
  },
  li: {
    color: "white",
    listStyle: "none",
    width: "auto",
    height: "100%",
    display: "block",
    cursor: "pointer",
  },
  link: {
    width: "auto",
    height: "100%",
    color: "inherit",
    display: "block",
    lineHeight: "20px",
    whiteSpace: "nowrap",
    textTransform: "capitalize",
    fontFamily: "inherit",
    fontSize: "13px",
    padding: 0,
    "&:hover,&:focus": {
      color: "#494641",
    },
  },
  linkActive: {
    width: "auto",
    height: "100%",
    color: "#fbd137",
    display: "block",
    lineHeight: "20px",
    whiteSpace: "nowrap",
    textTransform: "capitalize",
    fontFamily: "inherit",
    fontSize: "13px",
    padding: 0,
    "&:hover,&:focus": {
      color: "#fbd137",
    },
  },
  borde: {
    backgroundColor: "white",
    width: "2px",
    height: "15px",
    margin: "0 20px",
  },
  carrito: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  carritoContainer: {
    backgroundColor: "#22888c",
    padding: "1rem",
    borderRadius: "30px",
    marginLeft: "20px",
    listStyle: "none",
    width: "auto",
    height: "100%",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
    cursor: "pointer",
    "&:hover li svg": {
      fill: "#ffffff",
    },
    "&:hover": {
      backgroundColor: "#494641",
    },
  },
  icon: {
    marginRight: "10px",
    fill: "white",
  },
  subMenu: {
    color: "#494641",
    padding: "0.5rem",
    display: "block",
    width: "100%",
    "&:hover": {
      backgroundColor: "#22888c",
      color: "#ffffff",
    },
  },
  item: {
    margin: 0,
    color: "#ffffff",
  },
});

export default function NavDesktop({
  urlActive,
  handleLogout,
  isLogin,
  userName,
}) {
  const numArticulos = useSelector((store) => store.cart.num_articulos);

  const {
    ul,
    li,
    link,
    linkActive,
    borde,
    carrito,
    icon,
    carritoContainer,
    subMenu,
    item,
  } = useStyles();

  return (
    <>
      <ul className={ul}>
        <li className={li}>
          <Link
            to={`/productos`}
            className={urlActive == "productos" ? linkActive : link}
          >
            Productos
          </Link>
        </li>

        <div className={borde}></div>

        <li className={li}>
          <Link
            to={`/establecimientos`}
            className={urlActive == "establecimientos" ? linkActive : link}
          >
            ¡Ir de Tiendas!
          </Link>
        </li>

        <div className={borde}></div>

        {!isLogin ? (
          <>
            <li className={li}>
              <Link
                to={`/login`}
                className={urlActive == "login" ? linkActive : link}
              >
                Login / Registro
              </Link>
            </li>

            <div className={borde}></div>

            <li className={li}>
              <Link
                to={"/administrador"}
                target="_blank"
                className={urlActive == "acceso-empresas" ? linkActive : link}
              >
                Comercios
              </Link>
            </li>
          </>
        ) : (
          <>
            <li className={li}>
              <CustomDropdown
                noLiPadding
                buttonText={`Hola ${userName}!`}
                buttonProps={{
                  className: urlActive == "perfil" ? linkActive : link,
                  color: "transparent",
                }}
                dropdownList={[
                  <Link to={`/perfil`} className={subMenu}>
                    Perfil
                  </Link>,
                  <Link to={`/`} className={subMenu} onClick={handleLogout}>
                    Cerrar sesión
                  </Link>,
                ]}
              />
            </li>
          </>
        )}

        <div className={borde}></div>

        <li className={li}>
          <Link to={`/faq`} className={urlActive == "faq" ? linkActive : link}>
            ¿Cómo funciona?
          </Link>
        </li>

        <Link className={carritoContainer} to={`/carrito`}>
          <li className={carrito}>
            <ShoppingBasket className={icon} />
            <p className={item}>{numArticulos ? numArticulos : 0} artículo/s</p>
          </li>
        </Link>
      </ul>
    </>
  );
}
