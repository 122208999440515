import React from "react";

import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
/* import CustomDropdown from "components/CustomDropdown/CustomDropdown.js"; */
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "assets/img/logo/logo.png";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  ul: {
    fontSize: "18px",
    fontWeight: "700",
    margin: 0,
    padding: "50px 0 0 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Lato",
    width: "100%",
    height: "100%",
  },
  li: {
    listStyle: "none",
    width: "100%",
    height: "100%",
    display: "block",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    width: "100%",
    height: "50px",
    color: "inherit",
    textTransform: "capitalize",
    fontFamily: "inherit",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  linkActive: {
    width: "100%",
    height: "50px",
    color: "#ffffff",
    backgroundColor: "#7ab7ba",
    textTransform: "capitalize",
    fontFamily: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  carrito: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  carritoContainer: {
    margin: "20px 0 30px 0",
    backgroundColor: "#22888c",
    padding: "1rem",
    borderRadius: "10px",
    listStyle: "none",
    width: "auto",
    height: "100%",
    cursor: "pointer",
    "&:hover li svg": {
      fill: "#ffffff",
    },
    "&:hover": {
      backgroundColor: "#494641",
    },
  },
  icon: {
    marginRight: "10px",
    fill: "white",
  },
  logout: {
    listStyle: "none",
    height: "100%",
    display: "block",
    cursor: "pointer",
    marginLeft: "auto",
    marginRight: "20px",
    border: "2px solid #494641",
    borderRadius: "10px",
    padding: "0 0.5rem",
  },
  item: {
    margin: 0,
    color: "#ffffff",
  },
  containerLogo: {
    width: "100%",
    height: "auto",
    backgroundColor: "#e8f3f3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "1px solid #494641",
    padding: "1rem 0",
  },
  logo: {},
  anchor: {},
});

export default function NavMobil({
  urlActive,
  handleLogout,
  isLogin,
  userName,
  handleModalClose,
}) {
  const numArticulos = useSelector((store) => store.cart.num_articulos);
  const {
    ul,
    li,
    link,
    linkActive,
    carrito,
    icon,
    carritoContainer,
    logout,
    item,
    logo,
    anchor,
    containerLogo,
  } = useStyles();

  return (
    <>
      <ul className={ul}>
        {isLogin && (
          <li className={logout}>
            <Link
              to={`/`}
              className={urlActive == "/" ? linkActive : link}
              onClick={handleLogout}
            >
              Salir <ExitToAppIcon />
            </Link>
          </li>
        )}

        <li className={li}>
          <Link
            to={`/productos`}
            className={urlActive == "productos" ? linkActive : link}
            onClick={handleModalClose}
          >
            Productos
          </Link>
        </li>

        <li className={li}>
          <Link
            to={`/establecimientos`}
            className={urlActive == "establecimientos" ? linkActive : link}
            onClick={handleModalClose}
          >
            ¡Ir de Tiendas!
          </Link>
        </li>

        {!isLogin ? (
          <>
            <li className={li}>
              <Link
                to={`/login`}
                className={urlActive == "login" ? linkActive : link}
                onClick={handleModalClose}
              >
                Login / Registro
              </Link>
            </li>

            <li className={li}>
              <Link
                to={"/administrador"}
                className={urlActive == "acceso-empresas" ? linkActive : link}
                onClick={handleModalClose}
              >
                Comercios
              </Link>
            </li>
          </>
        ) : (
          <li className={li}>
            <Link
              to={`/perfil`}
              className={urlActive == "perfil" ? linkActive : link}
              onClick={handleModalClose}
            >
              {`Perfil ${userName}!`}
            </Link>
          </li>
        )}

        <li className={li}>
          <Link
            to={`/faq`}
            className={urlActive == "faq" ? linkActive : link}
            onClick={handleModalClose}
          >
            ¿Cómo funciona?
          </Link>
        </li>

        <Link
          className={carritoContainer}
          to={`/carrito`}
          onClick={handleModalClose}
        >
          <li className={carrito}>
            <ShoppingBasket className={icon} />
            <p className={item}>{numArticulos ? numArticulos : 0} artículo/s</p>
          </li>
        </Link>

        <div className={containerLogo}>
          <Link to={`/home`} className={anchor} onClick={handleModalClose}>
            <img src={Logo} className={logo} />
          </Link>
        </div>
      </ul>
    </>
  );
}
